<template>
  <div
    :class="{
      'has-border tw-border tw-border-solid tw-border-gray-400': !hasPhone
    }"
    class="driver-infos-container tw-rounded tw-w-full tw-p-2 date tw-flex tw-items-center tw-justify-between tw-flex-col tw-bg-gray-200"
  >
    <div
      v-if="hasPhone"
      class="tw-text-center"
    >
      <div class="label tw-text-xs tw-text-gray-600">
        {{ label | capitalize }}
      </div>
      <span :class="[dataClass]">
        {{ locationDateUpdate || '-' | capitalize }}
      </span>
    </div>

    <div
      v-else
      class="tw-w-full tw-text-gray-600 tw-text-xs tw-text-center"
      v-text="$trans('drivers.paragraphs.missing_drivers_phone_number')"
    />
  </div>
</template>

<script>
  export default {
    name: 'DriverInfosContainer',
    props: {
      label: {
        type: String,
        default: String,
        required: true
      },
      dataClass: {
        type: String,
        default: String
      },
      driver: {
        type: Object,
        default: Object,
        required: true
      }
    },
    computed: {
      hasPhone () {
        return !!this.driver.phone
      },
      locationDateUpdate () {
        return this.driver.location ? this.$moment(this.driver.location.updated_at).format('lll') : false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .driver-infos-container {
    &.date,
    &.hours {
      line-height: 16px;
    }

    &.dark {
      background: #FFF;
    }
  }

</style>
