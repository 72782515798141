var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-dialog',{attrs:{"modal-class":"driver-phone-number-dialog","persistent":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$trans(_vm.driver.phone ? 'drivers.buttons.edit_phone_number' : 'drivers.buttons.add_phone_number'))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-w-full"},[_c('ui-button',{staticClass:"tw-mr-2",attrs:{"variant":"link"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('cancel')))+" ")]),_c('ui-button',{attrs:{"id":"savePhoneNumber","loading":_vm.$wait.is('setting driver phone number'),"disabled":_vm.$wait.is('setting driver phone number'),"variant":"primary"},on:{"click":_vm.savePhone}},[_vm._v(" "+_vm._s(_vm.$trans(_vm.driver.phone ? 'drivers.buttons.edit_phone_number' : 'drivers.buttons.add_phone_number'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.modalState),callback:function ($$v) {_vm.modalState=$$v},expression:"modalState"}},[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"p-3"},[_c('icu',{staticClass:"tw-text-gray-700",attrs:{"variables":{
          name: ((_vm.driver.first_name) + " " + (_vm.driver.last_name)),
          phone: _vm.driver.phone
        },"path":_vm.driver.phone
          ? 'drivers.paragraphs.edit_driver_phone_number'
          : 'drivers.paragraphs.add_driver_phone_number',"tag":"p"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
          var slotValue = ref.value;
return [_c('span',{staticClass:"tw-font-bold",domProps:{"textContent":_vm._s(slotValue)}})]}},{key:"phone",fn:function(ref){
          var slotValue = ref.value;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.$options.filters.telephone(slotValue))}})]}}])}),_c('div',{staticClass:"tw-flex"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('phone'),"rules":("required|telephone:" + _vm.country),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('ctk-phone-number',{staticClass:"tw-flex-1",attrs:{"id":"PhoneNumberModification","country":_vm.country,"error":invalid && validated || !!_vm.errorMessage,"hint":invalid ? errors[0] : null,"name":"phone","autocomplete":"tel-national","required":""},on:{"update:country":function($event){_vm.country=$event}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}])})],1),(_vm.errorMessage)?_c('div',{staticClass:"error-container tw-rounded tw-text-red-500 py-2 px-3 mt-3",staticStyle:{"border":"1px solid"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }