<template>
  <div class="driver-infos-map">
    <div class="flex-fixed animated fadeInUp driver-infos-map__content tw-bg-white tw-absolute tw-bottom-0">
      <!--fadeOutUp-->
      <div
        v-if="!driver.location"
        class="no-location-data tw-absolute tw-overflow-x-hidden tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full"
      >
        <span
          class="no-location-data__label tw-font-medium tw-absolute tw-text-blue-500 tw-w-full tw-text-center"
          v-text="$trans('drivers.labels.no_gps_data')"
        />
        <picture
          class="tw-object-contain tw-select-none"
        >
          <source
            srcset="@/assets/img/illustrations/city-white.webp"
            type="image/webp"
          >
          <img
            src="@/assets/img/illustrations/city-white.png"
            alt=""
          >
        </picture>
      </div>
      <div class="driver-infos-map-header tw-relative tw-p-4">
        <div class="tw-flex tw-items-center tw-mb-4">
          <driver-personal
            :driver="driver"
            class="tw-mr-2"
          />
          <ui-button
            variant="link"
            class="tw-flex-shrink-0"
            @click="resetCurrentDriver"
          >
            <template #left-icon>
              <ui-ctk-icon
                name="close"
                data-test="icon"
              />
            </template>
          </ui-button>
        </div>
        <div class="tw-flex tw-justify-between">
          <driver-infos-container
            :label="$trans('drivers.labels.last_gps_position')"
            :driver="driver"
            data-class="tw-text-blue-500"
          />
        </div>
      </div>
      <hr class="tw-m-0">
      <driver-metrics
        v-if="metrics"
        :metrics="metrics.counts"
        :loader="metricsLoader"
      />
      <div class="tw-p-4">
        <div class="tw-flex tw-justify-between">
          <ui-button
            id="btn-delete-driver"
            class="tw-flex-1 tw-mr-4"
            variant="danger"
            outline
            @click="$emit('delete-driver')"
          >
            {{ $trans('drivers.buttons.delete_driver') }}
          </ui-button>
          <ui-button
            id="btn-modify-phone"
            class="tw-flex-1"
            variant="info"
            @click="$emit('open-phone-dialog')"
          >
            {{ $trans(driver.phone ? 'drivers.buttons.edit_phone_number' : 'drivers.buttons.add_phone_number') }}
          </ui-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import DriverInfosContainer from '@/views/Carriers/Drivers/components/DriverInfosContainer.vue'
  import DriverPersonal from '@/views/Carriers/Drivers/components/DriverPersonal.vue'
  import DriverMetrics from '@/views/Carriers/Drivers/components/DriverMetrics.vue'

  export default defineComponent({
    name: 'DriverInfosMap',
    components: {
      DriverMetrics,
      DriverInfosContainer,
      DriverPersonal
    },
    props: {
      driver: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        metrics: null,
        metricsLoader: false
      }
    },
    watch: {
      'driver.uuid' () {
        this.getMetrics()
      }
    },
    mounted () {
      this.getMetrics()
    },
    methods: {
      ...mapActions('drivers', ['getDriverMetrics', 'resetCurrentDriver']),
      getMetrics () {
        this.metricsLoader = true
        this.getDriverMetrics(this.driver.uuid)
          .then(data => {
            this.metrics = data
          })
          .catch(error => {
            if (!error.response) return

            this.metrics = null
            console.error('Error get driver metrics', error)
          })
          .finally(() => {
            this.metricsLoader = false
          })
      },
      labelClass (state) {
        switch (state) {
        case 'invited': case 'waiting_validation':
          return 'warning'
        case 'no_access': case 'disabled': case 'deleted':
          return 'danger'
        case 'validated':
          return 'teal'
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .driver-infos-map {
    &__content {
      right: 30px;
      z-index: 1;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
      width: 360px;

      @media only screen and (max-width: $breakpoint-tablet) {
        position: relative;
        width: 100%;
        right: 0;
      }
    }

    .no-location-data {
      top: -49px;

      img {
        height: 50px;
      }

      &__label {
        top: 25px;
      }
    }

    &-header {
      .close-btn {
        z-index: 999;
        background: transparent;
        line-height: 10px;
        outline: none;
      }
    }

    .btn-danger:hover {
      color: white !important;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 16px;
    }
  }

</style>
