<template>
  <div class="drivers-list-empty tw-flex tw-flex-col tw-items-center tw-justify-center tw-flex-1">
    <h2 class="drivers-list-empty__title mb-4 tw-font-light fs-18 tw-text-center">
      {{ $trans('drivers.titles.no_driver') }}
    </h2>

    <ui-button
      class="tw-py-0"
      variant="light"
      data-test="add-driver"
      @click="openAddDriverDialog"
    >
      <div class="tw-flex tw-items-center">
        <ui-ctk-icon
          name="drivers"
          data-test="icon"
        />
        {{ $trans('drivers.buttons.add_driver') }}
      </div>
    </ui-button>

    <div class="illustration" />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - driversListEmpty
   */
  export default defineComponent({
    name: 'DriversListEmpty',
    methods: {
      /**
       * @function openAddDriverDialog
       */
      openAddDriverDialog () {
        EventBus.$emit('drivers:open-add-driver-dialog')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .illustration {
    position: absolute;
    min-height: 70px;
    min-width: 100%;
    bottom: 0;

    &::before {
      content: '';
      height: 100%;
      left: 0;
      right: 0;
      background-image: url('~@/assets/img/illustration_search.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;
      opacity: 0.7;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      z-index: 1;
    }
  }


</style>
