<template>
  <div
    :style="width ? componentWidth : ''"
    class="driver-personal tw-min-w-0 tw-relative tw-flex tw-items-center tw-w-full"
  >
    <ctk-avatar
      class="flex-fixed tw-mr-3"
      :user="driver"
    />

    <div class="texts-container tw-flex-1 tw-text-left tw-truncate">
      <p class="tw-mb-0 tw-truncate driver-name tw-items-center">
        {{ driver.first_name.trim() | capitalize }} {{ driver.last_name.trim() | capitalize }}
      </p>
      <div class="tw-flex tw-justify-between tw-items-end">
        <p
          v-if="driver.phone"
          class="tw-mb-0 tw-truncate phone-number-container tw-text-gray-600 tw-text-xs"
        >
          {{ driver.phone | telephone }}
        </p>
        <UiBadge
          v-else-if="isNotActive"
          color="red"
          size="sm"
          class="no-active-container"
        >
          {{ $trans(`drivers.values.user_state.${driver.state}`) | capitalize }}
        </UiBadge>
        <div
          v-if="withGpsDate"
          v-b-tooltip.hover="$trans('drivers.labels.last_gps_position')"
          :class="[locationDateUpdate ? 'tw-text-blue-500' : 'tw-text-gray-500']"
          v-text="$trans(locationDateUpdate
            ? 'drivers.values.last_gps_date'
            : 'drivers.values.no_gps_data', {
            date: locationDateUpdate
          })"
          class="tw-text-xs gps-container"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'

  export default defineComponent({
    name: 'DriverPersonal',
    components: {
      CtkAvatar
    },
    props: {
      driver: {
        type: Object,
        required: true
      },
      width: {
        type: String,
        default: null
      },
      withGpsDate: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      componentWidth () {
        return {
          '-webkit-box-flex': 0,
          '-ms-flex': `0 0 ${this.width}`,
          flex: `0 0 ${this.width}`,
          'max-width': `${this.width}`,
          width: `${this.width}`
        }
      },
      isNotActive () {
        switch (this.driver.state) {
        case 'no_access': case 'disabled': case 'deleted':
          return true
        default:
          return false
        }
      },
      locationDateUpdate () {
        return this.driver.location && this.driver.location.updated_at
      }
    }
  })
</script>
