<template>
  <div class="drivers-list tw-flex tw-flex-col tw-flex-1 px-3 pb-3 pt-0">
    <template
      v-if="getDrivers.length > 0"
    >
      <drivers-list-header
        class="mb-2"
      />

      <transition-group
        name="list"
        class="drivers-list__content"
      >
        <drivers-list-item
          v-for="driver in getDrivers"
          :key="driver.uuid"
          :is-current="getCurrentDriver && driver.uuid === getCurrentDriver.uuid"
          :driver="driver"
          class="mb-2"
          @click.native="select(driver)"
        />
        <ctk-infinite-loader
          :loaded-text="$trans('drivers.paragraphs.all_loaded')"
          :load-more-text="''"
          :is-loading="false"
          :can-load-more="false"
          :items-count="getDrivers.length"
          key="infinite-loader"
        />
      </transition-group>
    </template>

    <drivers-list-empty
      class="tw-flex-1"
      v-else
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { EventBus } from '@/services/EventBus'

  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader'
  import DriversListHeader from './_subs/DriversListHeader'
  import DriversListEmpty from './_subs/DriversListEmpty'
  import DriversListItem from './_subs/DriversListItem'

  /**
   * @module component - driversList
   */
  export default {
    name: 'DriversList',
    components: {
      CtkInfiniteLoader,
      DriversListHeader,
      DriversListEmpty,
      DriversListItem
    },
    computed: {
      ...mapGetters('drivers', [
        'getDrivers',
        'getCurrentDriver'
      ])
    },
    methods: {
      ...mapActions('drivers', [
        'setCurrentDriver'
      ]),
      select (driver) {
        /**
         * Close the sidebar if we're in a mobile viewport
         */
        const width = document.documentElement.clientWidth
        if (width <= 768) {
          EventBus.$emit('drivers:sidebar-opening-update', false)
        }

        this.setCurrentDriver(driver)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .drivers-list {
    background: #F2F2F2;
    overflow-x: auto;

    &__content {
      overflow-y: auto;
    }
  }

</style>
