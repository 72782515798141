<template>
  <ctk-dialog
    v-model="modalState"
    modal-class="delete-driver-confirmation-dialog"
  >
    <template #title>
      <div>
        {{ $trans('drivers.titles.delete_driver') | capitalize }}
      </div>
    </template>
    <div class="px-3 pt-3">
      <p
        class="info-text text-seconday tw-text-center mt-3 mb-5"
        style="margin: 0 32px;"
      >
        {{ $trans('drivers.paragraphs.delete_driver.confirmation') }}
      </p>
      <ctk-info-container
        style="margin: 0 32px;"
      >
        <icu
          :variables="{
            name: `${driver.first_name} ${driver.last_name}`
          }"
          class="text-secondary"
          path="drivers.paragraphs.delete_driver.warning_deletion"
          tag="p"
          data-test="second-confirmation"
        >
          <template
            #name="{ value: slotValue }"
          >
            <span
              v-text="slotValue"
              class="tw-font-medium"
            />
          </template>
        </icu>
      </ctk-info-container>

      <div
        v-if="errorMessage"
        style="border: 1px solid;"
        class="error-container tw-rounded tw-text-red-500 py-2 px-3 mt-3"
      >
        {{ errorMessage }}
      </div>
      <div class="illustration" />
    </div>

    <template #footer>
      <div
        class="tw-flex tw-justify-between tw-items-center tw-w-full"
      >
        <ui-button
          variant="link"
          class="mr-2 cancel-link"
          @click="closeDialog"
        >
          {{ $t('cancel') | capitalize }}
        </ui-button>

        <ui-button
          :loading="$wait.is('deleting driver')"
          :disabled="$wait.is('deleting driver')"
          variant="primary"
          data-test="delete"
          @click="removeDriver(driver.uuid)"
        >
          {{ $trans('drivers.buttons.delete_driver_confirmation') | capitalize }}
        </ui-button>
      </div>
    </template>
  </ctk-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkInfoContainer from '@/components/CtkInfoContainer/index.vue'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - deleteDriverConfirmationDialog
   * @param {boolean} value
   * @param {object} driver
   */
  export default defineComponent({
    name: 'DeleteDriverConfirmationDialog',
    components: {
      CtkDialog,
      CtkInfoContainer
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      driver: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')

      return {
        modalState
      }
    },
    data () {
      return {
        /** @type {string|null} */
        errorMessage: null
      }
    },
    methods: {
      ...mapActions('drivers', ['deleteDriver']),
      /**
       * @function removeDriver
       * @param {string} uuid
       */
      removeDriver (uuid) {
        this.errorMessage = null
        this.$wait.start('deleting driver')
        this.deleteDriver(uuid)
          .then(() => {
            this.closeDialog()
          })
          // @ts-ignore
          .catch(err => {
            if (!err.response) return

            const { data } = err.response
            this.errorMessage = `${data.error.detail || data.error.title}`
          })
          .finally(() => {
            this.$wait.end('deleting driver')
          })
      },
      closeDialog () {
        this.errorMessage = null
        this.modalState = false
      }
    }
  })
</script>
