<template>
  <div
    class="drivers tw-flex tw-flex-1 tw-h-full"
  >
    <ctk-side-bar
      :width="350"
      :open="isSidebarOpen"
      @toggle-menu="toggleSidebarInvalidate"
    >
      <transition name="fade">
        <div
          v-if="$wait.is('fetching drivers')"
          class="load-layer"
        >
          <ui-loader
            :size="40"
            type="pallet"
            background-back="#000"
          />
        </div>
        <drivers-list
          v-else
          @open-add-driver-dialog="openAddDriverDialog"
        />
      </transition>
    </ctk-side-bar>
    <div class="main-content tw-flex tw-flex-1 tw-flex-col">
      <div class="tw-flex tw-flex-1 tw-h-full tw-flex-col">
        <drivers-map
          ref="driversMap"
          :drivers="getCurrentDriver ? [getCurrentDriver] : getDrivers"
          class="tw-flex tw-flex-col tw-h-full tw-flex-1"
          @open-add-driver-dialog="openAddDriverDialog"
        />
        <driver-infos-map
          v-if="!!getCurrentDriver"
          :driver="getCurrentDriver"
          @delete-driver="deleteDriver(getCurrentDriver)"
          @open-phone-dialog="openPhoneDialog(getCurrentDriver)"
        />
      </div>
    </div>

    <!-- Dialogs -->
    <delete-driver-confirmation-dialog
      v-model="dialogs.confirmDeleteDriver"
      :driver="driverToModify"
    />
    <add-driver-dialog
      v-model="dialogs.addDriverDialog"
    />
    <driver-phone-number-dialog
      v-model="dialogs.phoneNumber"
      :driver="driverToModify"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'
  import { EventBus } from '@/services/EventBus'

  import CtkSideBar from '@/components/CtkSideBar'
  import DriversList from './components/DriversList'
  import DriverInfosMap from './components/DriverInfosMap'
  import DeleteDriverConfirmationDialog from './components/Dialogs/DeleteDriverConfirmationDialog'
  import DriverPhoneNumberDialog from './components/Dialogs/DriverPhoneNumberDialog'
  import AddDriverDialog from './components/Dialogs/AddDriverDialog'
  import DriversMap from './components/DriversMap'

  /**
   * @module view - drivers
   */
  export default {
    name: 'Drivers',
    metaInfo () {
      return {
        title: this.$trans('drivers.titles.main')
      }
    },
    components: {
      CtkSideBar,
      DriverInfosMap,
      DeleteDriverConfirmationDialog,
      AddDriverDialog,
      DriverPhoneNumberDialog,
      DriversMap,
      DriversList
    },
    data () {
      return {
        driverToModify: {},
        fetchInterval: null,
        dialogs: {
          confirmDeleteDriver: false,
          phoneNumber: false,
          addDriverDialog: false
        }
      }
    },
    beforeRouteEnter (to, from, next) {
      if (store.getters['auth/isAccountLocked']) {
        next({
          name: 'MissionsRouter'
        })
        return
      }

      if (store.getters.isUserShipper) {
        next({
          name: 'Dashboard'
        })
        return
      }

      next()
    },
    computed: {
      ...mapGetters('ui', [
        'isSidebarOpen'
      ]),
      ...mapGetters('drivers', [
        'getCurrentDriver',
        'getDrivers'
      ])
    },
    created () {
      this.fetchDrivers()

      /**
       * Add closing events
       */
      EventBus.$on('drivers:sidebar-opening-update', (value) => {
        this.setSidebarOpen(value)
      })
      EventBus.$on('drivers:open-add-driver-dialog', () => {
        this.dialogs.addDriverDialog = true
      })
    },
    beforeDestroy () {
      const events = ['drivers:open-add-driver-dialog', 'drivers:sidebar-opening-update']
      events.forEach(e => EventBus.$off(e))

      if (this.fetchInterval) clearInterval(this.fetchInterval)
    },
    methods: {
      ...mapActions('ui', [
        'setSidebarOpen',
        'toggleSidebar'
      ]),
      ...mapActions('drivers', [
        'retrieveDrivers'
      ]),
      /**
       * @function fetchDrivers
       */
      fetchDrivers () {
        this.$wait.start('fetching drivers')
        this.retrieveDrivers()
          .finally(() => {
            this.$wait.end('fetching drivers')
            store.dispatch('setAppReady', true)
            if (process.env.NODE_ENV !== 'testing') {
              this.fetchInterval = setInterval(() => { this.retrieveDrivers() }, 30000)
            }
          })
      },
      /**
       * Toggle the sidebar and update the map to adapt it's layout
       * with the new page width.
       * @function toggleSidebarInvalidate
       */
      toggleSidebarInvalidate () {
        this.toggleSidebar()

        setTimeout(() => {
          const { driversMap: map } = this.$refs
          if (map && map.$refs.driversMap) map.$refs.driversMap.mapObject.invalidateSize()
        }, 100)
      },
      deleteDriver (driver) {
        this.driverToModify = driver
        this.dialogs.confirmDeleteDriver = true
      },
      openPhoneDialog (driver) {
        this.driverToModify = driver
        this.dialogs.phoneNumber = true
      },
      openAddDriverDialog () {
        this.dialogs.addDriverDialog = true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .drivers {
    h2 {
      font-size: 24px;
      font-weight: 300;
    }

    .main-content {
      position: relative;

      .page-content {
        overflow-y: auto;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

</style>
