<template>
  <div class="drivers-list-header tw-flex-shrink-0">
    <icu
      :variables="{
        count: getDrivers.length
      }"
      path="drivers.titles.drivers"
      tag="h1"
      class="tw-font-normal tw-flex tw-items-end drivers-list-header__title tw-text-2xl"
    >
      <template
        #count="{ value }"
      >
        <div
          class="tw-font-light tw-leading-none tw-mr-2 number"
          v-text="$n(value)"
        />
      </template>
    </icu>

    <div class="drivers-list-header__buttons tw-mt-3 tw-mb-2">
      <ui-button
        :title="$trans('drivers.buttons.add_driver')"
        class="add-driver-button tw-w-full"
        variant="info"
        data-test="add-driver-button"
        @click="openAddDriverDialog"
      >
        <template #left-icon>
          <ui-ctk-icon
            name="drivers"
            data-test="icon"
          />
        </template>
        <div
          v-text="$trans('drivers.buttons.add_driver')"
        />
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - driversListHeader
   */
  export default defineComponent({
    name: 'DriversListHeader',
    computed: {
      ...mapGetters('drivers', [
        'getDrivers'
      ])
    },
    methods: {
      /**
       * @function openAddDriverDialog
       */
      openAddDriverDialog () {
        EventBus.$emit('drivers:open-add-driver-dialog')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .drivers-list-header {
    min-height: 72px;

    &__title {
      line-height: 1.5;
      height: 60px;

      &__content {
        padding-top: 10px;
        padding-left: 6px;
      }

      .label {
        font-size: 22px;
        margin-top: auto;
      }

      .number {
        font-size: 48px;
      }
    }
  }

</style>
