<template>
  <div class="metrics tw-relative">
    <div class="tw-flex tw-py-2 tw-px-4">
      <icu
        :variables="{
          count: metrics.planned + metrics.in_progress
        }"
        class="tw-flex-1 tw-flex tw-items-center tw-flex-col tw-justify-center"
        path="drivers.values.missions"
        tag="div"
      >
        <template
          #count="{ value }"
        >
          <span
            class="missions-number tw-text-4xl tw-leading-none tw-font-light tw-m-0"
            v-text="$n(value)"
          />
        </template>
      </icu>
      <div
        class="tw-flex-1 tw-flex tw-flex-col tw-justify-around"
      >
        <div
          v-for="(number, key) in metricsAllowed"
          :key="key"
          class="tw-mb-1 metric-item"
        >
          <div class="tw-flex tw-justify-between">
            <span>{{ $trans(`drivers.values.metrics.state.${key}`, {
              count: 2
            }) | capitalize }}</span>
            <span
              class="tw-font-medium"
              data-test="value"
              v-text="$n(number)"
            />
          </div>
          <b-progress
            v-if="!loader"
            :variant="getVariant(key)"
            :value="number"
            :max="metrics.planned + metrics.in_progress"
            height="3px"
          />
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-text-right tw-flex tw-px-4 tw-py-1 tw-bg-gray-200">
      <div class="tw-flex-1" />
      <div class="tw-flex-1 tw-flex tw-justify-between">
        <span class="missions-completed-label">
          {{ $trans('drivers.values.metrics.state.completed', {
            count: 2
          }) | capitalize }}
        </span>
        <span class="missions-completed-number">
          {{ $n(metrics.completed) }}
        </span>
      </div>
    </div>
    <div
      v-show="loader"
      class="load-layer tw-absolute tw-top-0 tw-right-0 tw-left-0 tw-bottom-0 tw-z-50"
    >
      <ui-loader
        background-back="#000"
        :size="40"
        type="pallet"
      />
    </div>
  </div>
</template>

<script>
  const statesNotAllowed = ['total', 'completed']

  export default {
    name: 'DriverMetrics',
    props: {
      metrics: {
        type: Object,
        required: true
      },
      loader: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      metricsAllowed () {
        return Object.keys(this.metrics).reduce((object, key) => {
          if (!statesNotAllowed.includes(key)) {
            object[key] = this.metrics[key]
          }
          return object
        }, {})
      }
    },
    methods: {
      getVariant (key) {
        switch (key) {
        case 'planned':
          return 'warning'
        case 'in_progress':
          return 'info'
        default:
          return 'danger'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .metrics {
    .load-layer {
      background: rgba(255, 255, 255, 0.6);
    }
  }

</style>
