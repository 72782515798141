<template>
  <ctk-item
    :is-current="isCurrent"
    touchable-type="button"
    class="driver-card-item tw-flex tw-justify-between tw-items-center p-2 tw-rounded"
  >
    <driver-personal
      :driver="driver"
      with-gps-date
    />
  </ctk-item>
</template>

<script>
  import CtkItem from '@/components/CtkItem'
  import DriverPersonal from '@/views/Carriers/Drivers/components/DriverPersonal'

  export default {
    name: 'DriverCardItem',
    components: {
      CtkItem,
      DriverPersonal
    },
    props: {
      driver: {
        type: Object,
        required: true
      },
      isCurrent: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
