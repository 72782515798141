<template>
  <ctk-dialog
    v-model="modalState"
    modal-class="driver-phone-number-dialog"
    persistent
  >
    <template #title>
      <div
        data-test="title"
      >
        {{ $trans(driver.phone
          ? 'drivers.buttons.edit_phone_number'
          : 'drivers.buttons.add_phone_number') }}
      </div>
    </template>

    <ValidationObserver
      ref="observer"
    >
      <div class="p-3">
        <icu
          :variables="{
            name: `${driver.first_name} ${driver.last_name}`,
            phone: driver.phone
          }"
          :path="driver.phone
            ? 'drivers.paragraphs.edit_driver_phone_number'
            : 'drivers.paragraphs.add_driver_phone_number'"
          class="tw-text-gray-700"
          tag="p"
          data-test="content"
        >
          <template
            #name="{ value: slotValue }"
          >
            <span
              class="tw-font-bold"
              v-text="slotValue"
            />
          </template>
          <template
            #phone="{ value: slotValue }"
          >
            <span
              v-text="$options.filters.telephone(slotValue)"
            />
          </template>
        </icu>
        <div class="tw-flex">
          <ValidationProvider
            :name="$t('phone')"
            :rules="`required|telephone:${country}`"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-phone-number
                id="PhoneNumberModification"
                class="tw-flex-1"
                v-model="phoneNumber"
                :country.sync="country"
                :error="invalid && validated || !!errorMessage"
                :hint="invalid ? errors[0] : null"
                name="phone"
                autocomplete="tel-national"
                required
              />
            </template>
          </ValidationProvider>
        </div>
        <div
          v-if="errorMessage"
          style="border: 1px solid;"
          class="error-container tw-rounded tw-text-red-500 py-2 px-3 mt-3"
          data-test="error"
        >
          {{ errorMessage }}
        </div>
      </div>
    </ValidationObserver>

    <template #footer>
      <div
        class="tw-flex tw-justify-between tw-items-center tw-w-full"
      >
        <ui-button
          variant="link"
          class="tw-mr-2"
          data-test="cancel"
          @click="closeDialog"
        >
          {{ $t('cancel') | capitalize }}
        </ui-button>
        <ui-button
          id="savePhoneNumber"
          :loading="$wait.is('setting driver phone number')"
          :disabled="$wait.is('setting driver phone number')"
          variant="primary"
          @click="savePhone"
        >
          {{ $trans(driver.phone
            ? 'drivers.buttons.edit_phone_number'
            : 'drivers.buttons.add_phone_number') }}
        </ui-button>
      </div>
    </template>
  </ctk-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkPhoneNumber from '@/components/CtkPhoneNumber/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - DriverPhoneNumberDialog
   * @param {boolean} [value=false]
   * @param {object} driver
   */
  export default defineComponent({
    name: 'DriverPhoneNumberDialog',
    components: {
      CtkDialog,
      CtkPhoneNumber
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      driver: {
        type: Object,
        default: null
      }
    },
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')

      return {
        modalState
      }
    },
    data () {
      return {
        phoneNumber: null,
        /** @type {string|null} */
        country: null,
        /** @type {string|null} */
        errorMessage: null
      }
    },
    watch: {
      value (v) {
        if (v) {
          if (this.driver.phone) {
            const parsedPhone = parsePhoneNumberFromString(this.driver.phone)
            if (parsedPhone) {
              this.phoneNumber = parsedPhone.nationalNumber
              this.country = parsedPhone.country
            }
          } else {
            this.phoneNumber = null
            this.country = 'FR'
          }
        }
      }
    },
    methods: {
      ...mapActions('drivers', [
        'setPhoneNumber'
      ]),
      /**
       * @function savePhone
       */
      savePhone () {
        this.errorMessage = null
        this.$refs.observer.validate()
          .then(valid => {
            if (!valid || this.$wait.is('setting driver phone number')) return

            this.$wait.start('setting driver phone number')

            const parsedPhone = parsePhoneNumberFromString(this.phoneNumber, this.country)
            const phone = parsedPhone
              ? parsedPhone.number
              : this.phoneNumber

            this.setPhoneNumber({
              driver: this.driver,
              phone
            })
              .then(() => {
                this.closeDialog()
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  if (data.error.violations) {
                    data.error.violations.map((violation) => {
                      switch (violation.property_path) {
                      case 'phone':
                        this.errorMessage = `${this.$t('error')} : ${violation.message}`
                        break
                      }
                    })
                  } else {
                    this.errorMessage = `${this.$t('error')} : ${data.error.detail || data.error.title}`
                  }
                }
              })
              .finally(() => {
                this.$wait.end('setting driver phone number')
              })
          })
      },
      closeDialog () {
        this.errorMessage = null
        this.$emit('input', false)
      }
    }
  })
</script>
