<template>
  <ctk-dialog
    v-model="modalState"
    modal-class="add-driver-dialog"
    hide-footer
    persistent
  >
    <template #title>
      <div>
        {{ $trans('drivers.buttons.add_driver') | capitalize }}
      </div>
    </template>
    <ctk-loading-layer
      v-if="$wait.is('adding driver')"
    />
    <div
      class="px-3 pt-3"
      data-test="explanation"
    >
      <p class="tw-text-gray-700 m-0">
        {{ $trans('drivers.paragraphs.add_driver') }}
      </p>
    </div>
    <ctk-add-driver-form
      :error-message="errorMessage"
      :text-button="$trans('drivers.buttons.add_driver_confirmation') | capitalize"
      class="tw-px-4 tw-mt-4 tw-mb-2"
      @save-driver="submitted"
      @back="closeDialog"
      @close="closeDialog"
    />
  </ctk-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkAddDriverForm from '@/components/CtkAddDriverForm/index.vue'
  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * Shows a dialog with a form to add a new driver
   * @module component - addDriverDialog
   */
  export default defineComponent({
    name: 'AddDriverDialog',
    components: {
      CtkDialog,
      CtkAddDriverForm,
      CtkLoadingLayer
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')

      return {
        modalState
      }
    },
    data () {
      return {
        /** @type {string|null} */
        errorMessage: null
      }
    },
    methods: {
      ...mapActions('drivers', [
        'saveDriver',
        'isUserMono'
      ]),
      /**
       * @function submitted
       * @param {object} data - Driver data passed from the form
       */
      submitted (data) {
        this.$wait.start('adding driver')
        this.errorMessage = null
        this.saveDriver(data)
          .then(() => {
            this.closeDialog()
          })
          // @ts-ignore
          .catch((err) => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              if (data.error.violations) {
                // @ts-ignore
                data.error.violations.map((violation) => {
                  switch (violation.property_path) {
                  case 'phone':
                    this.errorMessage = `${this.$t('error')} : ${violation.message}`
                    break
                  }
                })
              }

              if (data.error.detail || data.error.title) {
                this.errorMessage = `${this.$t('error')} : ${data.error.detail || data.error.title}`
              }
            }
          })
          .finally(() => {
            this.$wait.end('adding driver')
          })
      },
      /**
       * Closes the current dialog and reset it's state
       * @function closeDialog
       */
      closeDialog () {
        this.errorMessage = null
        this.modalState = false
      }
    }
  })
</script>
