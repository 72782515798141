<template>
  <div
    class="drivers-map tw-flex tw-flex-1 tw-flex-col tw-h-full"
  >
    <l-map
      ref="driversMap"
      :zoom="mapData.zoom"
      :max-zoom="mapData.maxZoom"
      :min-zoom="mapData.minZoom"
      :options="mapData.options"
      :center="mapData.center"
      class="leaflet-map tw-flex tw-flex-col tw-flex-1 tw-h-full"
    >
      <l-tile-layer
        :url="mapData.url"
        :attribution="mapData.attribution"
      />
      <l-feature-group ref="markers">
        <l-marker
          v-for="driver in filteredDrivers"
          ref="driverMarker"
          :key="driver.uuid"
          :lat-lng="driver.location.latLng"
          :icon="driverIcon(driver.uuid)"
          @click="setCurrentDriver(driver)"
        >
          <l-popup
            ref="driverPopup"
            :content="formatPopup(`${driver.first_name} ${driver.last_name}`, driver.location.updated_at)"
            :options="{ closeButton: false }"
          />
        </l-marker>
      </l-feature-group>
    </l-map>

    <!-- Buttons -->
    <div class="drivers-map__buttons">
      <div
        v-if="getCurrentDriver"
        class="drivers-map__buttons__left tw-flex"
      >
        <ui-button
          class="tw-flex tw-items-center"
          variant="light"
          data-test="back-button"
          @click="setCurrentDriver(null)"
        >
          {{ $t('back') | capitalize }}
        </ui-button>
      </div>
    </div>
    <div
      v-if="drivers.length === 1 && !drivers[0].location"
      class="layer-no-location"
    />
  </div>
</template>

<script>
  import {
    LMap,
    LTileLayer,
    LFeatureGroup,
    LPopup,
    LMarker
  } from 'vue2-leaflet'
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import { icons, fitBoundsOptions } from '@/services/Leaflet'

  /**
   * @module component - driversMap
   * @param {Array} drivers
   */
  export default defineComponent({
    name: 'DriversMap',
    components: {
      LMap,
      LTileLayer,
      LFeatureGroup,
      LPopup,
      LMarker
    },
    props: {
      drivers: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        mapData: {
          zoom: 4,
          maxZoom: 14,
          minZoom: 2,
          options: {
            zoomControl: false,
            keyboard: false
          },
          controlZoomPosition: 'bottomright',
          center: [48.8664982, 2.3348235],
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
          url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'
        },
        L: null
      }
    },
    computed: {
      ...mapGetters('drivers', [
        'getCurrentDriver'
      ]),
      /**
       * Returns a list of drivers that have a location
       * @function filteredDrivers
       * @returns {Array} drivers
       */
      filteredDrivers () {
        return this.drivers
          // @ts-ignore
          .filter(({ location }) => location && !!location.lat && !!location.lng)
          .map(driver => ({
            ...driver,
            location: {
              ...driver.location,
              latLng: [driver.location.lat, driver.location.lng]
            }
          }))
      }
    },
    created () {
      this.L = window.L
    },
    watch: {
      getCurrentDriver: function () {
        this.$nextTick(() => {
          this.zoomToMarkers()
        })
      }
    },
    methods: {
      ...mapActions('drivers', [
        'setCurrentDriver'
      ]),
      driverIcon: icons.driver,
      /**
       * Returns a formatted html string for the popup
       * @function formatPopup
       * @returns {string} html
       */
      formatPopup (name, date) {
        return `
          <div class="header pickup">
            <b>${name}</b>
          </div>
          <div class="content tw-text-gray-700">
            ${this.$trans('drivers.labels.last_gps_position')}
            <b class="tw-text-blue-500">${this.$moment(date).format('lll')}</b>
          </div>
        `
      },
      /**
       * Fly to either the single marker or all of them
       * @function zoomToMarkers
       */
      zoomToMarkers () {
        try {
          const { driversMap } = this.$refs
          if (driversMap) {
            const map = driversMap.mapObject
            const markers = this.$refs.markers.mapObject
            const hasMarkers = markers.getLayers().length > 0

            if (map && markers && hasMarkers) {
              map.flyToBounds(markers.getBounds(), fitBoundsOptions)
              const { driverMarker } = this.$refs
              if (this.drivers.length === 1 && driverMarker[0]) {
                setTimeout(() => {
                  if (driverMarker && driverMarker[0] && driverMarker[0].mapObject) {
                    driverMarker[0].mapObject.openPopup()
                  }
                }, 900)
              } else {
                map.closePopup()
              }
            }
          }
        } catch (e) {
          console.error('Could not zoom to the marker.', e)
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .drivers-map {
    position: relative;
    overflow: hidden;

    .leaflet-map {
      background: #EAEAEA;
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 0;
    }

    &__buttons {
      z-index: 5;

      &__left {
        position: absolute;
        top: 16px;
        z-index: 2;
        left: 30px;

        @media only screen and (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
    }

    .layer-no-location {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 1;
      font-size: 18px;
      color: black;
    }
  }

</style>
